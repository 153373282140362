<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新增'}卡券`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="卡券编号">
        <en-input :model-value="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="卡券名称" prop="name">
        <en-input v-model="form.data.name" :disabled="form.data.status?.code === 'D'||form.data.status?.code === 'A'"></en-input>
      </en-form-item>
      <en-form-item label="卡券类型" prop="type.code">
        <select-maintain
          v-model="form.data.type"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MALCPTYPE']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          :disabled="form.data.status?.code === 'D'||form.data.status?.code === 'A'"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="面值">
        <en-input-number v-model="form.data.faceValue" :min="0" class="w-full" :disabled="form.data.status?.code === 'D'||form.data.status?.code === 'A'"></en-input-number>
      </en-form-item>
      <en-form-item label="满减金额">
        <en-input-number v-model="form.data.lowerLimitAmount" :min="0" class="w-full" :disabled="form.data.status?.code === 'D'||form.data.status?.code === 'A'"></en-input-number>
      </en-form-item>
      <en-form-item v-if="form.data.type?.discountMethod.code === 'F'" :label="form.data.type?.code === 'CHG' ? '赠送金额' : '抵扣金额'">
        <en-input-number v-model="form.data.discountValue" class="w-full" :disabled="form.data.status?.code === 'D'||form.data.status?.code === 'A'"></en-input-number>
      </en-form-item>
      <en-form-item label="折扣" v-if="form.data.type?.discountMethod.code === 'P'">
        <en-input-rate v-model="form.data.discountValue"></en-input-rate>
      </en-form-item>
      <en-form-item key="MTN" label="添加项目" v-if="form.data.type?.discountType.code === 'MTN'">
        <template #label>
          <div class="flex justify-between">
            <span>添加项目</span>
            <span class="text-xs text-primary">未选择项目，则所用项目都可以使用</span>
          </div>
        </template>
        <select-maintain
          v-model="form.data.maintenance"
          :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params, value) => (params.payload = { name: value }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
        >
        </select-maintain>
      </en-form-item>
      <en-form-item key="GDS" label="添加配件" v-if="form.data.type?.discountType.code === 'GDS'">
        <template #label>
          <div class="flex justify-between">
            <span>添加项目</span>
            <span class="text-xs text-primary">未选择配件，则所用项目都可以使用</span>
          </div>
        </template>
        <select-maintain
          v-model="form.data.goods"
          :ajax="{ action: 'GET /enocloud/common/goods', params: (params, value) => (params.payload = { name: value }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          
        >
        </select-maintain>
      </en-form-item>
      <en-form-item label="使用说明">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.data.status?.code === 'D'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
      <!-- <button-ajax :method="footer.pass.click" :params="row" type="primary" v-if="form.data.status.code === 'P'">审核</button-ajax> -->
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallCouponDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible && this.data?.id) {
          this.form.data.id = this.data.id
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data:{
          name: '',
        type: {
          code: '',
          discountMethod: { code: '' },
          discountType: { code: '' }
        },
        status: { code: '' },
        discountValue: 0,
        faceValue: 0,
        maintenance: {},
        goods: {},
        comment: ''
        },
        rules: {
          name: [{ required: true, message: '请输入卡券名称' }],
          'type.code': [{ required: true, message: '请选择卡券类型' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/mall/coupon/:couponId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          pass: {
            action: 'POST /enocloud/mall/coupon/:couponId/audit',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/mall/coupon',
           
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/mall/coupon',
           
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          pass: {
              async click() {

                await this.form.pass()
              }
            },
          confirm: {
            async click() {
              await this.form[this.form.data.id ? 'update' : 'submit']()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
